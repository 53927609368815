<template>
  <div class="skeleton-preloader-group-games" :class="{ 'skeleton-preloader-group-games_is-mobile': isMobile }">
    <div class="skeleton-preloader-group-games__header">
      <skeletor class="skeleton-preloader-group-games__title" as="div" />
      <skeletor class="skeleton-preloader-group-games__show-all" as="div" />
    </div>
    <div class="skeleton-preloader-group-games__games">
      <skeletor v-for="n in skeletonCount" :key="n" class="skeleton-preloader-group-games__game" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  interface IProps {
    gamesCount?: number;
    isMobile?: boolean;
  }

  const MOBILE_CARDS_COUNT = 9;
  const DESKTOP_CARDS_COUNT = 6;

  const props = defineProps<IProps>();

  const skeletonCount = computed(() => (props.isMobile ? MOBILE_CARDS_COUNT : DESKTOP_CARDS_COUNT));
</script>

<style src="~/assets/styles/components/skeleton-preloader/group-games.scss" lang="scss" />
